import { css, jsx } from '@emotion/react';
import { Breadcrumb, Icon } from 'antd';
import { get } from 'lodash';
import Link from 'next/link';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';

import sitemap from '@/sitemap';
import { bp, colors, colorWhite } from '@/styles';

const breadcrumbs = hideInMobile => css`
  display: ${hideInMobile ? 'none' : 'block'};
  @media ${bp.sm} {
    margin-bottom: 32px;
    display: block;
  }
  @media print {
    display: none;
    margin-bottom: 0;
  }
  .ant-breadcrumb-link {
    font-size: 1.6rem;
    line-height: 2.8rem;
    a {
      font-weight: 600;
      color: ${colors.secondaryGray};
    }
  }
  .ant-breadcrumb-separator {
    margin: 0 16px;
  }
`;

function findTitle(parts, slug, map) {
  const partsLength = parts.length;
  const chain = parts.reduce((acc, current, i) => {
    if (acc.includes('title')) return acc;
    const first = i === 0;
    const last = i === partsLength - 1;
    const end = current === slug;
    if (first) {
      return [current, partsLength === 1 || end ? 'title' : 'children'];
    }
    if (last || end) {
      return acc.concat([current, 'title']);
    }
    return acc.concat([current, 'children']);
  }, []);
  const chainCopy = [...chain];
  chainCopy.pop();
  const includeBreadcrumb = get(map, chainCopy, {}).breadcrumb !== false;
  return {
    breadcrumbName: get(map, chain),
    includeBreadcrumb,
  };
}

const Breadcrumbs = withRouter(props => {
  const { router, theme, overrideLast, hideHomePath, hidePaths, hideInMobile } = props;
  const { asPath } = router;
  const parts = asPath.replace(/\?.*/, '').split('/');
  // remove emptystring first item
  parts.shift();

  const white = theme === 'white' ? colorWhite : css``;

  const overrideList = [];
  if (overrideLast && Array.isArray(overrideLast)) {
    overrideList.push(...overrideLast);
  } else if (overrideLast) {
    overrideList.push(overrideLast);
  }

  return (
    <Breadcrumb
      css={breadcrumbs(hideInMobile)}
      separator={
        <span css={white}>
          <Icon type="right" />
        </span>
      }
      itemRender={(r, params, rs) => {
        const last = rs.indexOf(r) === rs.length - 1;
        if ((last && !r.forceBreadcrumb) || !r.includeBreadcrumb) {
          return <span css={white}>{r.breadcrumbName}</span>;
        }
        return (
          <Link href={r.path} passHref>
            <a css={white}>{r.breadcrumbName}</a>
          </Link>
        );
      }}
      routes={[
        ...(!hideHomePath
          ? [{ path: '/', breadcrumbName: 'Home', includeBreadcrumb: true, forceBreadcrumb: true }]
          : []),
        ...parts
          .map((part, i) => {
            const ancestry = [...Array(i + 1).keys()];
            const title = findTitle(parts, part, sitemap);
            return title.breadcrumbName && !hidePaths.includes(part)
              ? {
                  path: `/${ancestry.map(pi => parts[pi]).join('/')}`,
                  ...title,
                  forceBreadcrumb: false,
                }
              : false;
          })
          .filter(Boolean),
        ...overrideList.map(override => ({
          path: override.path,
          breadcrumbName: override.name,
          includeBreadcrumb: Boolean(override.breadcrumb),
          forceBreadcrumb: Boolean(override.breadcrumb),
        })),
      ]}
    />
  );
});

Breadcrumbs.propTypes = {
  theme: PropTypes.string,
  showCurrent: PropTypes.bool,
  overrideLast: PropTypes.oneOfType([
    PropTypes.shape({
      path: PropTypes.string,
      name: PropTypes.string,
      breadcrumb: PropTypes.bool,
    }),
    PropTypes.bool,
  ]),
  hidePaths: PropTypes.array,
  hideHomePath: PropTypes.bool,
  hideInMobile: PropTypes.bool,
};

Breadcrumbs.defaultProps = {
  theme: 'dark',
  showCurrent: true,
  overrideLast: false,
  hidePaths: [],
  hideHomePath: false,
  hideInMobile: false,
};

export default Breadcrumbs;
