import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';

import Breadcrumbs from '@/src/shared/components/Breadcrumbs';
import Container from '@/src/shared/components/Container';
import Divider from '@/src/shared/components/Divider';
import Heading from '@/src/shared/components/Heading';
import { bp, colors, colorWhite } from '@/styles';

import Sanitize from './Sanitize';

const style = {
  default: {
    header: css`
      padding: 32px 0;
      background-color: #fff;
      font-size: 1.6rem;
      line-height: 2.8rem;
      @media print {
        padding-bottom: 0;
      }
    `,
    title: css`
      color: ${colors.secondaryGrayDark};
    `,
    body: css`
      > p {
        margin-bottom: 20px;
        @media ${bp.md} {
          max-width: 66.667%;
        }
      }
      > *:last-child {
        margin-bottom: 0;
      }
    `,
  },
  image: {
    header: css`
      min-height: 375px;
      position: relative;
      background-position: center;
      background-size: cover;
      padding-top: 24px;
      padding-bottom: 24px;
      @media (max-width: ${bp.sizes.sm}px) {
        background-color: #00407d;
        background-image: none !important;
        min-height: 0;
      }
      @media ${bp.md} {
        min-height: 430px;
        padding-top: 32px;
        padding-bottom: 32px;
      }
      &::after {
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: linear-gradient(287.06deg, rgba(238, 238, 238, 0) 0%, #00407d 100%);
        z-index: 2;
        @media (max-width: ${bp.sizes.sm}px) {
          content: none;
        }
      }
      & > div {
        z-index: 3;
        position: relative;
      }
    `,
    title: css`
      ${colorWhite}
      @media print {
        color: #000 !important;
      }
    `,
    body: css`
      color: white !important;
      div {
        color: white !important;
        .sanitized {
          color: white !important;
        }
      }
    `,
  },
  carousel: {
    header: css`
      position: relative;
      &::before {
        background-image: linear-gradient(287.06deg, rgba(238, 238, 238, 0) 0%, #00407d 100%);
        content: '';
        display: block;
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      .ant-carousel {
        line-height: 1;
      }
      .ant-carousel .slick-dots {
        z-index: 4;
      }
    `,
    title: css`
      ${colorWhite}
      @media print {
        color: #000 !important;
      }
    `,
    body: css``,
    breadcrumbs: css`
      left: 0;
      padding-top: 32px;
      position: absolute;
      right: 0;
      width: 100%;
      z-index: 3;
    `,
  },
  title: css`
    margin-bottom: 24px;
    /* @media ${bp.md} {
      max-width: 75%;
    } */
    @media print {
      font-size: 2.6rem;
      line-height: 3.2rem;
    }
  `,
  subtitle: css`
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 800;
    margin-bottom: 1.2rem;
    @media ${bp.sm} {
      font-size: 3.6rem;
    }
  `,
  abovetitle: css`
    h5 {
      font-size: 1.2rem !important;
      font-weight: bold !important;
      line-height: 2rem !important;
      text-transform: uppercase !important;
    }
  `,
  divider: css`
    @media print {
      display: none;
    }
  `,
};

const Hero = props => {
  const {
    type,
    title,
    subTitle,
    children,
    bgImage,
    isCarousel,
    overrideLast,
    hidePaths,
    hideHomePath,
    hideInMobile,
    aboveTitle,
    ..._props
  } = props;

  const heroProps = {
    css: style[type].header,
    ...(type === 'image' && { style: { backgroundImage: `url(${bgImage})` } }),
  };

  const titleCss = [];
  if (type === 'image') titleCss.push(colorWhite);

  const breadcrumbProps = { overrideLast, hidePaths, hideHomePath, hideInMobile };

  return (
    <header {...heroProps} {..._props}>
      {type === 'carousel' ? (
        <>
          <Container css={style.carousel.breadcrumbs}>
            <Breadcrumbs
              theme={['carousel', 'image'].includes(type) ? 'white' : 'dark'}
              {...breadcrumbProps}
            />

            <Heading type={1} theme="jumbo" css={[style.title, style[type].title]}>
              {title}
            </Heading>
          </Container>
          {children}
        </>
      ) : (
        <Container>
          <Breadcrumbs
            theme={['carousel', 'image'].includes(type) ? 'white' : 'dark'}
            {...breadcrumbProps}
          />
          {type === 'default' && <Divider type="red" css={style.divider} />}
          {aboveTitle ? (
            <div css={[style.title, style.abovetitle]}>
              <Sanitize>{aboveTitle}</Sanitize>
            </div>
          ) : null}
          {title && (
            <Heading type={1} theme="jumbo" css={[style.title, style[type].title]}>
              {title}
            </Heading>
          )}
          {subTitle && <div css={[style.subtitle, style[type].title]}>{subTitle}</div>}
          <div css={style[type].body}>{children}</div>
        </Container>
      )}
    </header>
  );
};

Hero.propTypes = {
  type: PropTypes.oneOf(['default', 'carousel', 'image']),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node,
  bgImage: PropTypes.string,
  overrideLast: PropTypes.oneOfType([
    PropTypes.shape({
      path: PropTypes.string,
      name: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  aboveTitle: PropTypes.node,
  hidePaths: PropTypes.array,
  hideHomePath: PropTypes.bool,
  hideInMobile: PropTypes.bool,
};

Hero.defaultProps = {
  type: 'default',
  children: <></>,
  bgImage: '',
  overrideLast: false,
  aboveTitle: '',
  hidePaths: [],
  hideHomePath: false,
  hideInMobile: false,
};

export default Hero;
